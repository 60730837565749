import {
  DashboardGrid,
  IndicatorCard,
  RelationBarChart,
  DashboardSectionTitle,
  TableWrapper,
  Table,
} from '@/shared/components';
import { gridSettings } from './grid-settings';
import { decimalNoFraction, percent, setDisplayedValue, decimalOnePlace, shouldRenderCard } from '@/shared/format';
import { TwoPeopleIcon } from '@grupoboticario/flora-react-icons';

export function ContactProblemsDashboard({ data, isLoading }) {
  return (
    <>
      <DashboardSectionTitle
        title="Problemas de contato"
        subtitle="Tentativa de contato por parte da força de vendas."
      />
      <DashboardGrid css={gridSettings(shouldRenderCard(data?.activeBase.status))}>
        {shouldRenderCard(data?.activeBase.status) && (
          <IndicatorCard
            title="Base ativa"
            tooltip="Quantidade de revendedores que fizeram pedido em até 6 ciclos, excluindo os Inicios e Reinícios"
            mainValue={setDisplayedValue(data?.activeBase?.realized, decimalNoFraction)}
            icon={<TwoPeopleIcon />}
            isLoading={isLoading}
            gridArea="active-base"
          />
        )}
        <IndicatorCard
          title="Total de problemas"
          tooltip="Quantidade total de problemas encontrados no relacionamento com os revendedores."
          mainValue={setDisplayedValue(data?.problems?.totalProblems?.realized, decimalNoFraction)}
          auxiliarText={
            data?.problems.totalProblems.average !== undefined
              ? `Média de ${decimalOnePlace.format(data?.problems.totalProblems.average)} problemas por RE`
              : '-'
          }
          isLoading={isLoading}
          gridArea="total-resellers"
        />
        <IndicatorCard
          title="REs únicas com problemas"
          tooltip="Quantidade de revendedores únicos, cpfs únicos,  que apresentaram problemas de contato."
          mainValue={setDisplayedValue(data?.problems?.distinctProblemsResellers?.realized, decimalNoFraction)}
          isLoading={isLoading}
          gridArea="distinct-resellers"
        />
        <IndicatorCard
          title="Percentual da base ativa com problemas"
          tooltip="Percentual de revendedores ativos que não puderam ser contatados devido a algum problema."
          mainValue={setDisplayedValue(data?.problems.baseProblemsPercentage.percentage, percent)}
          isLoading={isLoading}
          gridArea="active-base-percentage"
        />
        <RelationBarChart
          chartData={data?.problems.graph}
          chartTitle="REs únicas por problemas de contato"
          tooltip="Quantidade de revendedores únicos, cpfs únicos, que apresentaram problemas de contato."
          gridArea="problems-chart"
          isLoading={isLoading}
          baseColor="#D63E33"
        />
        <TableWrapper
          selectorKind="problema"
          isLoading={isLoading}
          hasSelector
          gridArea="tables"
          title="Percentual da base com problemas por Meu Clube Multi e Nível de inatividade"
          tooltipText="Percentual de revendedores ativos que não puderam ser contatados devido a algum problema, classificados nas diferentes faixa do Meu Clube Multi e níveis de inatividade"
          tables={[
            <Table
              isLoading={isLoading}
              dashboard="base"
              type="inactivity"
              data={data?.problems?.inactivityLevel}
              key="digitalPerInactivityLevel"
            />,
            <Table
              isLoading={isLoading}
              dashboard="base"
              type="my-gb-club"
              data={data?.problems?.mcmLevel}
              key="digitalPerInactivityLevel"
            />,
          ]}
        />
      </DashboardGrid>
    </>
  );
}
