import {
  SubreasonProvider,
  TriggersDashboard,
  AttendanceDashboard,
  ContactProblemsDashboard,
  DailyRelationDashboard,
} from '@/features';
import { PageTitle, FilterDrawer, TabNavigation, Notification } from '@/shared/components';
import { useGetDashboardData } from '@/shared/hooks';
import { Flex } from '@grupoboticario/flora-react';
import { useState } from 'react';

export function Relation() {
  const { data, isLoading } = useGetDashboardData({ queryKey: 'relation', path: '/relation/dashboard' });
  const [isDailyDashboard, setIsDailyDashboard] = useState(true);

  return (
    <SubreasonProvider>
      <Flex align="center" justify="space-between">
        <PageTitle>Indicadores de Relacionamento</PageTitle>
        <FilterDrawer setIsDailyDashboard={setIsDailyDashboard} isDailyDashboard={isDailyDashboard} />
      </Flex>
      <Notification />
      <TabNavigation
        setIsDailyDashboard={setIsDailyDashboard}
        consolidated={
          <>
            <TriggersDashboard data={data} isLoading={isLoading} />
            <AttendanceDashboard data={data} isLoading={isLoading} />
            <ContactProblemsDashboard data={data} isLoading={isLoading} />
          </>
        }
        daily={<DailyRelationDashboard />}
      />
    </SubreasonProvider>
  );
}
