import { usePermissions } from '@/shared/hooks';
import { Box, Flex, Text } from '@grupoboticario/flora-react';
import { CrossIcon, ExclamationMarkHexagonIcon } from '@grupoboticario/flora-react-icons';
import { useState } from 'react';

export function Notification() {
  const { data: isFranchise, isLoading } = usePermissions('can-view-franchisee');

  const [isNotificationVisible, setIsNotificationVisible] = useState(true);
  return (
    <Flex
      gap="$1"
      direction="column"
      css={{
        width: '100%',
        backgroundColor: '$statusInfoBackgroundDefault',
        padding: '$3',
        borderRadius: '8px',
        border: '1px solid $statusInfoNonInteractiveEmphasis',
        display: isNotificationVisible && !isFranchise && !isLoading ? 'flex' : 'none',
        marginTop: '$4',
      }}
    >
      <Flex justify="space-between">
        <Flex gap="$1">
          <ExclamationMarkHexagonIcon color="$statusInfoNonInteractiveEmphasis" />
          <Text weight="heavy" size="bodyLargeStandardMedium" color="$statusInfoNonInteractiveEmphasis">
            Resultados Monomarca Indisponíveis
          </Text>
        </Flex>
        <Box onClick={() => setIsNotificationVisible(false)} css={{ cursor: 'pointer' }}>
          <CrossIcon size={16} color="$statusInfoNonInteractiveEmphasis" />
        </Box>
      </Flex>
      <Text size="bodySmallStandardRegular" color="$statusInfoNonInteractiveEmphasis">
        No momento, a VDI não exibe resultados das estruturas monomarca. Agradecemos sua compreensão!
      </Text>
    </Flex>
  );
}
