/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactEChartsProps } from '@/shared/components';
import { decimal, decimalNoFraction, percent, percentNoFraction } from '../number';
import { formatTimeSeriesValues } from '@/features';
import { ChartData } from '@/features/DailyRelationDashboard/components/ColumnLineChart';

interface TooltipProps {
  title?: string;
  marker: string;
  name: string;
  amount: number;
  percentage: number;
}

export function generateTooltip({ title = '', marker, name, amount, percentage }: TooltipProps) {
  return `<div>
      ${title && `<p>${title}</p>` + '<hr />'}
      <div style="display: 'flex'; flex-direction: row">
        <span>${marker}</span>
        <strong>${name}</strong>
        <span style="padding-left: 20px">${amount ? decimal.format(amount) : ''}</span>
        <span style="padding-left: 10px">${percentage}%</span>
      </div>
    </div>`;
}

export function generateTimeSeriesTooltip({ value, displayedChart }) {
  return `<div>
      <p>Ciclo ${value[0].axisValue}</p>
      <hr />
      <div style="display: flex; flex-direction: column; justify-content: space-between">
        ${value
          .map(item => {
            return `
            <div style="display: flex; flex-direction: row; justify-content: space-between">
              <div>
                <span>${item.marker}</span>
                <strong>${item.seriesName}</strong>
              </div>
              <span style="padding-left: 40px">${formatTimeSeriesValues(item.value[1], displayedChart)}</span>
            </div>
          `;
          })
          .join('')}
        </div>
    </div>`;
}

export function setTooltip(tooltipTitle?): ReactEChartsProps['option']['tooltip'] {
  return {
    trigger: 'item',
    borderRadius: 6,
    borderColor: '#fff',
    axisPointer: {
      type: 'shadow',
    },
    formatter: value => {
      return generateTooltip({
        title: tooltipTitle,
        marker: value.marker,
        name: value.name,
        amount: value.data.resellers,
        percentage: value.value,
      });
    },
  };
}

export const formatTooltipBar = (title: string, value, formatter) => {
  return `
    <div style="margin-bottom: 8px; border-bottom: 1px solid #ccc; padding-bottom: 8px">${title}</div>
    <div style="display: flex; align-items: center; justify-content: space-between; width: 240px">
      <div>${value.name}</div>
      <div style="display: flex; align-items: center">
        <div style="width: 12px; height: 12px; background-color: ${value.color}; border-radius: 50%; margin: 0 8px;"></div>
        <div>${formatter.format(formatter === percent || formatter === percentNoFraction ? value.value / 100 : value.value)}</div>
      </div>
    </div>
  `;
};

export function relationTooltip(title, subtitle, marker, value, percentage) {
  return `
    <div style="margin-bottom: 8px; border-bottom: 1px solid #ccc; padding-bottom: 8px; font-weight: 600">${title}</div>
    <div style="display: flex; align-items: center; justify-content: space-between">
      <div style="padding-right: 20px">${subtitle}</div>
      <div style="display: flex; align-items: center">
        ${marker} ${decimalNoFraction.format(value)} (${percentNoFraction.format(percentage / 100)})
      </div>
    </div>
  `;
}

export function dailyColumnTooltip(value: any, type: 'acionamento' | 'atendimento'): string {
  return `
  <div style="display: flex; flex-direction: column; gap: 16px;">
    <div style="border-bottom: 1px solid #ccc; padding-bottom: 8px; font-weight: 600; color:#000000;">${value[0].name} - ${value[0].data.date}</div>
    <div style="font-weight: 600; color:#00325F">Resultado do dia</div>
    <div style="display: flex; flex-direction: space-between;">
        <div style="font-weight: 500; color:##222222; margin-right: 24px; width: 240px;">REs com ${type}</div>
        <div>
          ${value[0].marker}
          ${decimalNoFraction.format(value[0].value)}
        </div>
    </div>
    <div style="display: flex; flex-direction: space-between;">
        <div style="font-weight: 500; color:##222222; margin-right: 24px; width: 240px;">${type === 'atendimento' ? 'Percentual da base atendida' : 'Percentual da base acionada'}</div>
        <div>
          ${value[1].marker}
          ${percentNoFraction.format(value[1].data.percentage / 100)}
        </div>
    </div>
    <div style="font-weight: 600; color:#00325F">Acumulado no ciclo</div>
    <div style="display: flex; flex-direction: space-between;">
        <div style="font-weight: 500; color:##222222; margin-right: 24px; width: 240px;">REs únicas com ${type}</div>
        <div>
          ${value[0].marker}
          ${decimalNoFraction.format(value[0].data.accumulatedValue)}
        </div>
    </div>    <div style="display: flex; flex-direction: space-between;">
        <div style="font-weight: 500; color:##222222; margin-right: 24px; width: 240px;">${type === 'atendimento' ? 'Contato efetivo acumulado' : 'Giro de base acumulado'}</div>
        <div>
          ${value[1].marker}
          ${percentNoFraction.format(value[1].value / 100)}
        </div>
    </div>
  </div>
    `;
}

export function dailyStackedTooltip(value, data: ChartData[], type: 'Giro de base' | 'Contato Efetivo'): string {
  const columnData = data.filter(item => `Dia ${item.cycleDay}` === value[0].name);
  return `
<div style="display: flex; flex-direction: column; gap: 16px;">
    <div style="border-bottom: 1px solid #ccc; padding-bottom: 8px; font-weight: 600; color:#000000;">${value[0].name} - ${columnData[0].date}</div>
        <div style="display: flex; flex-direction: column; gap: 16px; ">
        <div style="font-weight: 600; color:#00325F">Resultado do dia</div>
        <div style="font-weight: 500; padding-right: 40px;">${type} por nível de inatividade</div>
            <div style="display: flex; flex-direction: column; gap: 8px;">
                ${value
                  .map(
                    item => `
                  <div style="display: flex; justify-content: space-between; align-items: center;">
                      <div style="font-weight: 600">${item.seriesName}</div>
                      <div style="display: flex; align-items: center;">
                          ${item.marker}
                          <div style="width: 60px; text-align: right;">${decimalNoFraction.format(item.data.absoluteValue || 0)}</div>
                          <div style="width: 60px; text-align: right;">(${percentNoFraction.format(item.value / 100 || 0)})</div>                      </div>
                  </div>
                `,
                  )
                  .join('')}
            </div>
        </div>
    </div>
</div>


  `;
}

//
