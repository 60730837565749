import { DashboardGridProps } from '@/shared/components/DashboardGrid/DashboardGrid';

export const gridSettings: DashboardGridProps['css'] = {
  '@mobile': {
    gridTemplateAreas: `
    "total-base total-base"
    "total-base-actives total-base-actives"
    "total-base-activity total-base-activity"
    "active-base active-base"
    "active-base-actives active-base-actives"
    "active-base-activity active-base-activity"
    "multi-brand-actives multi-brand-actives"
    "digital-channel-penetration digital-channel-penetration"
    "er-penetration er-penetration"
    "inactivity-level inactivity-level"
    "my-gb-club my-gb-club"
    `,
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  '@tablet': {
    gridTemplateAreas: `
    "total-base total-base"
    "total-base-actives total-base-activity"
    "active-base active-base"
    "active-base-actives active-base-activity"
    "multi-brand-actives multi-brand-actives"
    "digital-channel-penetration er-penetration"
    "inactivity-level inactivity-level"
    "my-gb-club my-gb-club"
    `,
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  '@desktop': {
    gridTemplateAreas: `
    "total-base total-base total-base-actives total-base-actives total-base-activity total-base-activity"
    "active-base active-base active-base-actives active-base-actives active-base-activity active-base-activity"
    "multi-brand-actives multi-brand-actives digital-channel-penetration digital-channel-penetration er-penetration er-penetration"
    "inactivity-level inactivity-level inactivity-level inactivity-level inactivity-level inactivity-level"
    "my-gb-club my-gb-club my-gb-club my-gb-club my-gb-club my-gb-club"
    `,
    gridTemplateColumns: 'repeat(6, 1fr)',
  },
};
