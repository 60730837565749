import { useReactTable, ColumnDef, getCoreRowModel, createColumnHelper, flexRender } from '@tanstack/react-table';
import {
  StyledTable,
  StyledTd,
  StyledTh,
  TableSkeleton,
  TableCellText,
  InactivityLevelName,
  GbClubLevelName,
  ProgressBar,
} from '@/shared/components';
import { currencyNoFraction, decimalNoFraction, tableColumnsLabelMap } from '@/shared/format';
import { Flex, ScrollArea } from '@grupoboticario/flora-react';
import { useEffect, useState } from 'react';

type ColumnHelperMetaType = {
  isHidden?: boolean;
};

interface TableData {
  classification: string;
}

const tableConfig = {
  activity: {
    channel: 'Penetração por meio de captação',
    brand: 'Penetração por marcas',
    format: decimalNoFraction.format,
  },
  revenue: {
    channel: 'Receita GMV por meio de captação',
    brand: 'Receita GMV por marcas',
    format: currencyNoFraction.format,
  },
  base: {
    format: decimalNoFraction.format,
  },
};

interface TableProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  dashboard: 'activity' | 'revenue' | 'base' | 'relation';
  type: 'my-gb-club' | 'inactivity';
  title?: string;
  isLoading?: boolean;
  tooltipText?: string;
}

const Table = ({ data, dashboard, type, isLoading = true }: TableProps) => {
  const [tableData, setTableData] = useState([]);
  const allColumns = new Set<string>(data?.flatMap(item => Object.keys(item)));

  const fixedHeaders = Array.from(allColumns).filter(key => {
    return Array.isArray(data[0][key]) && key !== 'channel' && key !== 'brand';
  });

  const fixedColumnsWithoutHeaderNames = Array.from(allColumns).filter(key => {
    return !Array.isArray(data[0][key]) && key !== 'code';
  });

  const fixedColumnsWithHeaderNames = Array.from(
    new Set<string>(
      data?.flatMap(item =>
        Object.keys(item)
          .filter(key => key !== 'channel' && key !== 'brand')
          .flatMap(key => item[key]?.map?.(({ code }) => code)),
      ),
    ),
  ).filter(code => code !== undefined);

  const toggableColumnsNames = Array.from(
    new Set<string>(
      data?.flatMap(item =>
        Object.keys(item)
          .filter(key => key === 'channel' || key === 'brand')
          .flatMap(key => item[key]?.map?.(({ code }) => code)),
      ),
    ),
  ).filter(code => code !== undefined);

  const columnHelper = createColumnHelper<TableData>();

  function generateColumnsAccessor(code) {
    return columnHelper.accessor(code, {
      header: code,
      cell: props => {
        const progressBarColumns = ['digitalChannelsPenetration'];

        if (progressBarColumns.some(columnId => props.column.id === columnId)) {
          return <ProgressBar value={props.getValue()?.percentage} />;
        }

        return (
          <TableCellText
            props={props}
            isActivityDashboard={dashboard === 'activity'}
            format={tableConfig[dashboard]?.format}
            value={props.getValue()?.value}
            percentage={props.getValue()?.percentage}
            auxiliarText={props.row.original.classification}
          />
        );
      },
    });
  }

  const classificationColumn = {
    id: 'classification',
    meta: {
      isHidden: true,
    },
    columns: [
      columnHelper.accessor('classification', {
        header: 'Classificação',
        cell: props => {
          return type === 'inactivity' ? (
            <InactivityLevelName level={props.getValue()} />
          ) : (
            <GbClubLevelName level={props.getValue()} />
          );
        },
      }),
    ],
  };

  const fixedColumns = {
    id: 'fixedColumns',
    meta: {
      isHidden: true,
    },
    columns: fixedColumnsWithoutHeaderNames.map(column => generateColumnsAccessor(column)),
  };

  const headerColumns = {
    id: 'headerColumn',
    header: 'Atividade',
    columns: fixedColumnsWithHeaderNames.map(column => generateColumnsAccessor(column)),
  };

  const togglableColumns = {
    id: 'togglableColumn',
    header: tableConfig[dashboard][allColumns.has('channel') ? 'channel' : 'brand'],
    columns: toggableColumnsNames.map(code => generateColumnsAccessor(code)),
  };

  const Columns: ColumnDef<TableData>[] = [classificationColumn, fixedColumns, headerColumns, togglableColumns].filter(
    item => item.columns.length > 0,
  );

  useEffect(() => {
    if (data) {
      const tableData = data?.map(item => {
        const channels = {};
        const fixedColumnsWithHeader = {};
        const fixedColumnsWithoutHeader = {};

        const channelOrBrand = Object.keys(item).find(key => key === 'channel' || key === 'brand');

        toggableColumnsNames?.forEach(code => {
          channels[code] = item[channelOrBrand]?.find(value => value?.code === code);
        });

        fixedColumnsWithHeaderNames?.forEach(code => {
          fixedColumnsWithHeader[code] = item[fixedHeaders[0]]?.find(value => value?.code === code);
        });

        fixedColumnsWithoutHeaderNames?.forEach(column => {
          fixedColumnsWithoutHeader[column] = item[column];
        });

        return {
          classification: item.code,
          ...fixedColumnsWithoutHeader,
          ...fixedColumnsWithHeader,
          ...channels,
        };
      });
      setTableData(tableData);
    }
  }, [data]);

  const table = useReactTable<TableData>({
    data: tableData,
    columns: Columns,
    getCoreRowModel: getCoreRowModel(),
  });
  const paddingValues = '98px';

  return (
    <Flex direction="column" css={{ gridArea: type, maxWidth: `calc(100vw - ${paddingValues})` }}>
      <ScrollArea css={{ scrollHeight: '4px', paddingBottom: '20px' }} orientation="horizontal" type="auto">
        <StyledTable>
          {isLoading ? (
            <TableSkeleton />
          ) : (
            <>
              <thead>
                {table.getHeaderGroups().map(headerGroup => {
                  const hasParentHeader = fixedHeaders.length > 0 || toggableColumnsNames.length > 0;
                  if (!hasParentHeader && headerGroup.id === '0') {
                    return;
                  }
                  return (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map(header => {
                        const meta: ColumnHelperMetaType = header.column.columnDef.meta;

                        const isClassificationColumn = header.column.id === 'classification';
                        const isTogglableColumn = header.column.id === 'togglableColumn';
                        const isHeaderColumn = header.column.id === 'headerColumn';

                        const headerColumnChildren = headerGroup.headers.filter(
                          header => header.column.parent?.id === 'headerColumn',
                        );
                        const isLastHeaderColumnChildren =
                          headerColumnChildren[headerColumnChildren.length - 1]?.id === header.id;
                        return (
                          <StyledTh
                            css={{
                              borderBottom: meta?.isHidden ? 'none' : '1px solid #ccc',
                              borderRight: isHeaderColumn || isLastHeaderColumnChildren ? '1px solid #ccc' : 'none',
                              textAlign:
                                isHeaderColumn || isTogglableColumn
                                  ? 'center'
                                  : isClassificationColumn
                                    ? 'left'
                                    : 'right',
                            }}
                            key={header.id}
                            colSpan={header.colSpan}
                          >
                            {flexRender(
                              tableColumnsLabelMap[header.column.columnDef.header.toString()] ||
                                header.column.columnDef.header,
                              header.getContext(),
                            )}
                          </StyledTh>
                        );
                      })}
                    </tr>
                  );
                })}
              </thead>
              <tbody>
                {table.getRowModel().rows.map(row => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map(cell => {
                      const headerColumnChildren = cell.column.parent?.columns.filter(
                        column => column.parent?.id === 'headerColumn',
                      );
                      const isLastHeaderColumnChildren =
                        headerColumnChildren[headerColumnChildren.length - 1]?.id === cell.column.id;

                      const isClassificationRow = cell.row.original.classification === 'Total';

                      return (
                        <StyledTd
                          css={{
                            whiteSpace: 'nowrap',
                            borderRight: isLastHeaderColumnChildren ? '1px solid #ccc' : 'none',
                            borderBottom: isClassificationRow ? 'none' : '1px solid #ccc',
                          }}
                          key={cell.id}
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </StyledTd>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </>
          )}
        </StyledTable>
      </ScrollArea>
    </Flex>
  );
};

export { Table };
