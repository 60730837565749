import { DashboardGrid, IndicatorCard, DashboardSectionTitle, TableWrapper, Table } from '@/shared/components';
import { setDisplayedValue, decimalNoFraction, decimalOnePlace, percent, shouldRenderCard } from '@/shared/format';
import { gridSettings } from './grid-settings';
import { AttendanceGraphsCard, RelationStackedChart, SubReasonsDonutChart } from './components';
import { TwoPeopleIcon } from '@grupoboticario/flora-react-icons';
import { useSubreasonDispatch } from './context';
import { useEffect } from 'react';

export function AttendanceDashboard({ data, isLoading }) {
  const dispatch = useSubreasonDispatch();

  function onChartClick(event) {
    dispatch({ type: 'SELECT_REASON', payload: { reason: event.name } });
  }

  useEffect(() => {
    if (data) {
      dispatch({ type: 'SET_CHART_DATA', payload: { data: data?.attendance.graph } });
    }
  }, [data]);

  return (
    <>
      <DashboardSectionTitle
        title="Atendimentos"
        subtitle="Ação de conversa entre assistentes e REs de forma ativa e receptiva."
      />
      <DashboardGrid css={gridSettings(shouldRenderCard(data?.activeBase.status))}>
        {shouldRenderCard(data?.activeBase.status) && (
          <IndicatorCard
            title="Base ativa"
            tooltip="Quantidade de revendedores que fizeram pedido em até 6 ciclos, excluindo os Inicios e Reinícios"
            mainValue={setDisplayedValue(data?.activeBase?.realized, decimalNoFraction)}
            icon={<TwoPeopleIcon />}
            isLoading={isLoading}
            gridArea="active-base"
          />
        )}
        <IndicatorCard
          title="Total de atendimentos"
          tooltip="Quantidade total de atendimentos realizados para os revendedores, no ciclo corrente."
          mainValue={setDisplayedValue(data?.attendance?.totalAttendance?.realized, decimalNoFraction)}
          auxiliarText={
            data?.attendance?.totalAttendance?.average !== undefined
              ? `Média de ${decimalOnePlace.format(data?.attendance.totalAttendance.average)}  conversas por RE`
              : '-'
          }
          isLoading={isLoading}
          gridArea="total-resellers"
        />
        <IndicatorCard
          title="REs únicas com atendimento"
          tooltip="Quantidade de revendedores únicos, CPFs únicos, que tiveram um atendimento, no ciclo corrente."
          mainValue={setDisplayedValue(data?.attendance?.distinctAttendedResellers?.realized, decimalNoFraction)}
          isLoading={isLoading}
          gridArea="distinct-resellers"
        />
        <IndicatorCard
          title="Contato efetivo"
          tooltip="Percentual de revendedores únicos da Base ativa que tiveram um atendimento."
          mainValue={setDisplayedValue(data?.attendance?.effectiveContact?.percentage, percent)}
          isLoading={isLoading}
          gridArea="base-rotation"
        />
        <AttendanceGraphsCard
          gridArea="triggers-chart"
          stackedBarChart={
            <RelationStackedChart
              chartData={data?.attendance?.graph}
              chartTitle="REs únicas por motivo de atendimento"
              tooltip="Quantidade de revendedores únicos, classificados pelos diferentes motivos de atendimento"
              isLoading={isLoading}
              onClick={onChartClick}
            />
          }
          donutChart={
            <SubReasonsDonutChart
              tooltip="Quantidade de revendedores únicos, classificados pelos diferentes submotivos de atendimento"
              isLoading={isLoading}
            />
          }
        />
        <TableWrapper
          selectorKind="atendimento"
          isLoading={isLoading}
          hasSelector
          gridArea="tables"
          title="Contato efetivo por Meu Clube Multi e Nível de inatividade"
          tooltipText="Percentual de revendedores únicos da Base ativa que tiveram um atendimento,  classificados nas diferentes faixa do Meu Clube Multi e níveis de inatividade"
          tables={[
            <Table
              isLoading={isLoading}
              dashboard="base"
              type="inactivity"
              data={data?.attendance?.inactivityLevel}
              key="digitalPerInactivityLevel"
            />,
            <Table
              isLoading={isLoading}
              dashboard="base"
              type="my-gb-club"
              data={data?.attendance?.mcmLevel}
              key="digitalPerInactivityLevel"
            />,
          ]}
        />
      </DashboardGrid>
    </>
  );
}
