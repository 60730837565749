import { Card, CardTitle, ChartLegend, ReactECharts, ReactEChartsProps } from '@/shared/components';
import { percentNoFraction, dailyStackedTooltip } from '@/shared/format';
import { lighten, mix } from 'polished';

interface ChartData {
  cycleDay: number;
  date: string;
  values: {
    inactivityLevel: string;
    value: number;
    percentage: number;
  }[];
}

interface StackedBarChartProps {
  data: ChartData[];
  isLoading: boolean;
  baseColor: string;
  chartTitle: string;
  tooltipText: string;
  type?: 'Giro de base' | 'Contato Efetivo';
}

function scale(baseColor, scaleSize) {
  const lightenColors = Array.from({ length: scaleSize - 1 }, (_, i) =>
    mix(1 - (i + 1) / scaleSize, baseColor, lighten(0.5, baseColor)),
  );

  return [baseColor, ...lightenColors];
}

const levelDictionary = {
  A1: 'I1',
  A2: 'I2',
  A3: 'I3',
};

const inactivityLevelsCustomOrder = ['A0', 'A1', 'A2', 'A3', 'I4', 'I5', 'I6', 'I7+'];

export function StackedBarChart({ data, isLoading, baseColor, chartTitle, tooltipText, type }: StackedBarChartProps) {
  const isMobile = window.innerWidth < 768;
  const isTablet = window.innerWidth < 992;

  const colors = scale(baseColor, inactivityLevelsCustomOrder?.length);
  const columnsNames = inactivityLevelsCustomOrder.map(level => ({
    name: levelDictionary[level] || level,
  }));

  const series: ReactEChartsProps['option']['series'] = inactivityLevelsCustomOrder.map(level => ({
    name: levelDictionary[level] || level,
    type: 'bar',
    stack: 'total',
    barWidth: '60%',
    label: {
      show: true,
      formatter: ({ value }) => percentNoFraction.format(Number(value) / 100),
    },
    itemStyle: {
      color: colors[inactivityLevelsCustomOrder.indexOf(level)] || '#f0f0f0',
    },
    data: data?.map(item => ({
      value: item.values.find(value => level === value.inactivityLevel)?.percentage,
      absoluteValue: item.values.find(value => level === value.inactivityLevel)?.value,
    })),
  }));

  const option: ReactEChartsProps['option'] = {
    tooltip: {
      trigger: 'axis',
      borderWidth: 0,
      confine: true,
      extraCssText: 'font:inherit;',
      formatter: value => dailyStackedTooltip(value, data, type),
    },
    grid: {
      top: '8%',
      right: isMobile ? '14%' : isTablet ? '8%' : 0,
      bottom: isTablet ? '14%' : '2%',
      left: '2%',
      containLabel: true,
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: value => percentNoFraction.format(value / 100),
      },
      max: 100,
    },
    xAxis: {
      type: 'category',
      data: data?.map(item => `Dia ${item.cycleDay}`),
      axisLabel: {
        interval: 0,
      },
    },
    dataZoom: [
      {
        type: 'slider',
        show: isTablet,
        xAxisIndex: 0,
        start: 0,
        end: isMobile ? 24 : isTablet ? 38 : 100,
        maxSpan: isMobile ? 24 : isTablet ? 38 : 100,
        zoomLock: true,
        brushSelect: false,
        handleSize: 0,
      },
    ],

    series,
  };

  return (
    <Card css={{ height: '100%' }}>
      <CardTitle title={chartTitle} tooltip={tooltipText} />
      <ReactECharts
        option={{ ...option }}
        style={{
          width: '100%',
          height: '380px',
          marginBottom: '16px',
        }}
        loading={isLoading}
      />
      {!isLoading && <ChartLegend columns={columnsNames} colors={colors} />}
    </Card>
  );
}
