import { authService } from '@vdi/auth-service';

export async function fetchPermission(feature) {
  const { token } = await authService.getTokenOrRedirect();

  const request = await fetch(`${process.env.API_BASE_URL}/filters/feature?feature=${feature}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'content-type': 'application/json',
    },
  });

  if (!request.ok) {
    throw new Error(`Network response was not ok`);
  }

  return request.json();
}
