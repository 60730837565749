import { DEFAULT_LOCALE } from './locale';

export const currency = new Intl.NumberFormat(DEFAULT_LOCALE, {
  style: 'currency',
  currency: 'BRL',
  currencyDisplay: 'narrowSymbol',
  maximumFractionDigits: 2,
});

export const currencyNoFraction = new Intl.NumberFormat(DEFAULT_LOCALE, {
  style: 'currency',
  currency: 'BRL',
  currencyDisplay: 'narrowSymbol',
  maximumFractionDigits: 0,
});
