import { classificationLevelLabelMap, percentNoFraction, decimalOnePlace } from '@/shared/format';
import { Flex } from '@grupoboticario/flora-react';
import { StyledPercentage } from '@/shared/components';

export function TableCellText({ value, format, isActivityDashboard, percentage, auxiliarText, props }) {
  const formattedPercent = percentNoFraction.format(percentage / 100);

  const shouldHidePercentage =
    (props.column.parent.id === 'togglableColumn' && isActivityDashboard) ||
    props.row.original.classification === 'Total';

  const displayedMainText = () => {
    if (value === undefined) return '-';
    if (isActivityDashboard && props.column.parent.id === 'togglableColumn') {
      return formattedPercent;
    }

    const percentValueColumns = ['baseRotation', 'effectiveContact', 'baseProblemsPercentage'];

    if (percentValueColumns.some(columnId => props.column.id === columnId)) {
      return formattedPercent;
    }
    return format(value);
  };

  const displayedAuxiliarText = () => {
    const averagePerResellerColumns =
      props.column.id === 'totalAttendance' ||
      props.column.id === 'totalTriggers' ||
      props.column.id === 'totalProblems';

    const isTotalColumns =
      props.column.id === 'base' ||
      props.column.id === 'activeBase' ||
      props.column.id === 'revenue' ||
      props.column.id === 'totalBase' ||
      props.column.id === 'distinctAttendedResellers' ||
      props.column.id === 'distinctProblemsResellers' ||
      props.column.id === 'distinctTriggeredResellers';

    const withoutAuxiliarTextColuns =
      props.column.id === 'baseRotation' ||
      props.column.id === 'effectiveContact' ||
      props.column.id === 'baseProblemsPercentage';

    if (withoutAuxiliarTextColuns || shouldHidePercentage) {
      return null;
    }

    if (averagePerResellerColumns) {
      return `Média de ${decimalOnePlace.format(percentage)} por RE`;
    }
    if (percentage === null || percentage === undefined || shouldHidePercentage) return '-';

    if (auxiliarText === 'novoRevendedor' || auxiliarText === 'indefinido') {
      return formattedPercent;
    }

    if (isTotalColumns) {
      return `${formattedPercent} do total`;
    }

    return `${formattedPercent} dos ${classificationLevelLabelMap[auxiliarText] || auxiliarText}`;
  };

  return (
    <Flex css={{ textAlign: 'right' }} direction="column">
      {displayedMainText()}
      <StyledPercentage>{displayedAuxiliarText()}</StyledPercentage>
    </Flex>
  );
}
