export const classificationLevelLabelMap = {
  diamante: 'Diamantes',
  esmeralda: 'Esmeraldas',
  rubi: 'Rubis',
  platina: 'Platinas',
  ouro: 'Ouros',
  prata: 'Pratas',
  bronze: 'Bronzes',
  novoRevendedor: 'revendedores',
  indefinido: 'sem classificação',
  I0: 'Inícios',
  'I7+': 'Reinícios',
  A0: 'Inícios',
};

export const brandsLabelMap = {
  oui: 'O.U.I',
  eud: 'Eudora',
  boti: 'O Boticário',
  qdb: 'Quem Disse Berenice?',
  aum: 'AU.Migos',
  others: 'Outros',
};

export const tableColumnsLabelMap = {
  revenue: 'Receita GMV',
  activeBase: 'Base ativa',
  actives: 'Ativas',
  base: 'Base ativa',
  eudora: 'Eudora',
  oBoticario: 'O Boticário',
  multimarca: 'Multimarca',
  total: 'Geral',
  boti: 'BOTI',
  aum: 'AU.M',
  oui: 'O.U.I',
  qdb: 'QDB',
  eud: 'EUD',
  multiB: 'Multi B',
  appRe: 'App RE',
  er: 'ER',
  appSuper: 'Super FV',
  portal: 'Portal',
  superFv: 'Super FV',
  totalBaseActives: 'Ativos da base total',
  digitalChannelsPenetration: 'Penetração nos canais digitais',
  blocked: 'Base indisponível',
  others: 'Outros',
  totalBase: 'Base total',
  attendances: 'Atendimentos',
  resellersAverage: 'Média por RE',
  resellers: 'REs únicas com acionamento',
  effectiveContact: 'Contato efetivo',
  distinctTriggeredResellers: 'REs únicas com acionamentos',
  totalTriggers: 'Total de acionamentos',
  baseRotation: 'Giro de base',
  distinctProblemsResellers: 'REs únicas com problemas',
  totalProblems: 'Total de problemas',
  baseProblemsPercentage: 'Percentual da base com problemas',
  distinctAttendedResellers: 'Res únicas com atendimentos',
  totalAttendance: 'Total de atendimentos',
};
