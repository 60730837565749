import { TabList, Tab, TabPanel, Tabs, Text } from '@grupoboticario/flora-react';

interface TabNavigationProps {
  consolidated: React.ReactNode;
  historical?: React.ReactNode;
  daily?: React.ReactNode;
  setIsDailyDashboard: (value: boolean) => void;
}

export function TabNavigation({ consolidated, historical, daily, setIsDailyDashboard }: TabNavigationProps) {
  return (
    <Tabs defaultValue="tab1" orientation="horizontal">
      <TabList aria-label="over-view-timeseries-tab" css={{ marginBottom: '12px' }}>
        <Tab onClick={() => setIsDailyDashboard(true)} value="tab1">
          <Text>Consolidado</Text>
        </Tab>
        {historical && (
          <Tab value="tab2">
            <Text>Visão Histórica</Text>
          </Tab>
        )}
        {daily && (
          <Tab onClick={() => setIsDailyDashboard(false)} value="tab3">
            <Text>Curva diária</Text>
          </Tab>
        )}
      </TabList>
      <TabPanel css={{ padding: '0' }} value="tab1">
        {consolidated}
      </TabPanel>
      <TabPanel css={{ padding: '0' }} value="tab2">
        {historical}
      </TabPanel>
      <TabPanel css={{ padding: '0' }} value="tab3">
        {daily}
      </TabPanel>
    </Tabs>
  );
}
