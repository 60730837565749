import { Button, Flex } from '@grupoboticario/flora-react';
import { useState } from 'react';

export const ToggleButton = ({ onLeftButtonClick, leftText, onRightButtonClick, rightText, isLoading }) => {
  const [isLeftButtonActive, setIsLeftButtonActive] = useState(true);

  return (
    <Flex direction="row" css={{ marginTop: '1rem', '@tablet': { marginTop: 0 } }}>
      <Button
        disabled={isLoading}
        size="small"
        hierarchy={isLeftButtonActive ? 'primary' : 'secondary'}
        css={{
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          padding: '0.5rem 1rem',
        }}
        onClick={() => {
          onLeftButtonClick();
          setIsLeftButtonActive(true);
        }}
        isFull
      >
        {leftText}
      </Button>
      <Button
        disabled={isLoading}
        size="small"
        hierarchy={isLeftButtonActive ? 'secondary' : 'primary'}
        css={{
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          padding: '0.5rem 1rem',
        }}
        onClick={() => {
          onRightButtonClick();
          setIsLeftButtonActive(false);
        }}
        isFull
      >
        {rightText}
      </Button>
    </Flex>
  );
};
