import { OverviewDashboard } from '@/features';
import { PageTitle, FilterDrawer, Notification } from '@/shared/components';
import { Flex } from '@grupoboticario/flora-react';

export function Overview() {
  const pageTitle = 'Visão Geral';

  return (
    <>
      <Flex
        align="center"
        justify="space-between"
        css={{ paddingBottom: '$4', borderBottom: '1px solid $nonInteractiveAltAuxiliar' }}
      >
        <PageTitle>{pageTitle}</PageTitle>
        <FilterDrawer />
      </Flex>
      <Notification />
      <OverviewDashboard />
    </>
  );
}
