import { ReactEChartsProps } from '@/shared/components';
import { decimal } from '../number';

function sortColumnsByCustomOrder({ columns, customOrderArray }) {
  return columns?.sort((a, b) => customOrderArray.indexOf(a.name) - customOrderArray.indexOf(b.name));
}

function sortColumnsByAscendingName({ columns }) {
  return columns?.sort((a, b) => a.name.localeCompare(b.name));
}

export function setChartsSeries({ columns, columnsCustomOrder }): ReactEChartsProps['option']['series'] {
  const sortColumns = columnsCustomOrder
    ? sortColumnsByCustomOrder({ columns, customOrderArray: columnsCustomOrder })
    : sortColumnsByAscendingName({ columns });

  return sortColumns?.map(column => {
    return {
      type: 'bar',
      seriesLayoutBy: 'row',
      name: column.name,
      data: [
        {
          code: column.code,
          resellers: column.resellers,
          value: (column.percentage ?? 0).toFixed(0),
          name: column.name,
        },
      ],
      label: {
        show: true,
        position: 'inside',
        formatter: value => `${column.resellers ? decimal.format(value.data.resellers) : ''}\n${value.value}%`,
        color: '#fff',
        textBorderColor: 'inherit',
        textBorderWidth: 3,
      },
    };
  });
}
