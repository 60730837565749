export const gridSettings = {
  marginTop: 0,
  '@mobile': {
    gridTemplateAreas: `
          "inactivity"
          "my-gb-club"
          `,
    gridTemplateColumns: 'repeat(1, 1fr)',
  },
  '@tablet': {
    gridTemplateAreas: `
          "inactivity"
          "my-gb-club"
          `,
    gridTemplateColumns: 'repeat(1, 1fr)',
  },
  '@desktop': {
    gridTemplateAreas: `
          "inactivity inactivity inactivity my-gb-club my-gb-club my-gb-club"
          `,
    gridTemplateColumns: 'repeat(6, 1fr)',
  },
};
