import { DashboardSectionTitle } from '@/shared/components';
import { ColumnLineChart, StackedBarChart } from './components';
import { Flex } from '@grupoboticario/flora-react';
import { useGetDashboardData } from '@/shared/hooks';

export function DailyRelationDashboard() {
  const { data, isLoading } = useGetDashboardData({ queryKey: 'dailyRelation', path: '/relation/daily' });

  return (
    <Flex gap="$4" direction="column">
      <DashboardSectionTitle
        title="Acionamentos"
        subtitle="Ações realizadas pela força de vendas para acionar uma determinada RE."
      />
      <ColumnLineChart
        chartTitle="REs com acionamentos e giro de base"
        tooltipText="Quantidade de revendedores únicos, que possuem acionamento, por dia e percentual do giro de base acumulado ao longo do ciclo selecionado."
        data={data?.trigger.general}
        isLoading={isLoading}
        columnColor="#3E4C55"
        lineColor="#52886D"
        type="acionamento"
      />
      <StackedBarChart
        chartTitle="REs acionadas por nível de inatividade"
        tooltipText="Quantidade de revendedores únicos, que possuem acionamento classificados pelos diferentes níveis de inatividade, por dia do ciclo."
        baseColor="#596D79"
        data={data?.trigger.inactivityLevel}
        isLoading={isLoading}
        type="Giro de base"
      />
      <DashboardSectionTitle
        title="Atendimentos"
        subtitle="Ações realizadas pela força de vendas para acionar uma determinada RE."
      />
      <ColumnLineChart
        chartTitle="REs com atendimento e contato efetivo"
        tooltipText="Quantidade de revendedores únicos, que possuem atendimento, por dia e percentual do contato efetivo acumulado ao longo do ciclo selecionado."
        data={data?.attendance.general}
        isLoading={isLoading}
        columnColor="#364372"
        lineColor="#EB9D03"
        type="atendimento"
      />
      <StackedBarChart
        chartTitle="REs com atendimento por nível de inatividade"
        tooltipText="Quantidade de revendedores únicos, que possuem atendimento classificados pelos diferentes níveis de inatividade, por dia do ciclo."
        baseColor="#3C4D88"
        data={data?.attendance.inactivityLevel}
        isLoading={isLoading}
        type="Contato Efetivo"
      />
    </Flex>
  );
}
