import {
  DashboardGrid,
  IndicatorCard,
  RelationBarChart,
  DashboardSectionTitle,
  Table,
  TableWrapper,
} from '@/shared/components';
import { decimalNoFraction, percent, setDisplayedValue, decimalOnePlace, shouldRenderCard } from '@/shared/format';
import { gridSettings } from './grid-settings';
import { TwoPeopleIcon } from '@grupoboticario/flora-react-icons';

export function TriggersDashboard({ data, isLoading }) {
  return (
    <>
      <DashboardSectionTitle
        title="Acionamentos"
        subtitle="Ações realizadas pela força de vendas para acionar uma determinada RE."
      />
      <DashboardGrid css={gridSettings(shouldRenderCard(data?.activeBase.status))}>
        {shouldRenderCard(data?.activeBase.status) && (
          <IndicatorCard
            title="Base ativa"
            tooltip="Quantidade de revendedores que fizeram pedido em até 6 ciclos, excluindo os Inicios e Reinícios"
            mainValue={setDisplayedValue(data?.activeBase?.realized, decimalNoFraction)}
            icon={<TwoPeopleIcon />}
            isLoading={isLoading}
            gridArea="active-base"
          />
        )}
        <IndicatorCard
          title="Total de acionamentos"
          tooltip="Quantidade total de acionamento realizados para os revendedores, no ciclo corrente."
          mainValue={setDisplayedValue(data?.trigger.totalTriggers?.realized, decimalNoFraction)}
          auxiliarText={
            data?.trigger.totalTriggers.average !== undefined
              ? `Média de ${decimalOnePlace.format(data?.trigger.totalTriggers.average)} acionamentos por RE`
              : '-'
          }
          isLoading={isLoading}
          gridArea="total-resellers"
        />
        <IndicatorCard
          title="REs únicas com acionamentos"
          tooltip="Quantidade de revendedores únicos, CPFs únicos, que receberam um acionamento, no ciclo corrente."
          mainValue={setDisplayedValue(data?.trigger.distinctTriggeredResellers?.realized, decimalNoFraction)}
          isLoading={isLoading}
          gridArea="distinct-resellers"
        />
        <IndicatorCard
          title="Giro de base"
          tooltip="Percentual de revendedores únicos da Base ativa que receberam um acionamento."
          mainValue={setDisplayedValue(data?.trigger.baseRotation.percentage, percent)}
          isLoading={isLoading}
          gridArea="base-rotation"
        />
        <RelationBarChart
          chartData={data?.trigger.graph}
          chartTitle="REs únicas por motivo de acionamento"
          tooltip="Quantidade de revendedores únicos, classificados pelos diferentes tipos de acionamentos."
          gridArea="triggers-chart"
          isLoading={isLoading}
          baseColor="#596D79"
        />
        <TableWrapper
          selectorKind="acionamento"
          isLoading={isLoading}
          hasSelector
          gridArea="tables"
          title="Giro de base por Meu Clube Multi e Nível de inatividade"
          tooltipText="Percentual de revendedores únicos da Base ativa que receberam um acionamento, classificados nas diferentes faixa do Meu Clube Multi e níveis de inatividade"
          tables={[
            <Table
              isLoading={isLoading}
              dashboard="base"
              type="inactivity"
              data={data?.trigger?.inactivityLevel}
              key="digitalPerInactivityLevel"
            />,
            <Table
              isLoading={isLoading}
              dashboard="base"
              type="my-gb-club"
              data={data?.trigger?.mcmLevel}
              key="digitalPerInactivityLevel"
            />,
          ]}
        />
      </DashboardGrid>
    </>
  );
}
