import { Card, CardTitle, ChartLegend, ReactECharts, ReactEChartsProps } from '@/shared/components';
import { decimalNoFraction, percentNoFraction, relationTooltip } from '@/shared/format';
import { Flex } from '@grupoboticario/flora-react';

export function RelationBarChart({ chartData, chartTitle, gridArea, isLoading, tooltip }) {
  const isMobile = window.innerWidth < 768;
  const isTablet = window.innerWidth < 992;

  const dataInAscedingOrder = chartData?.sort((a, b) => b.resellers - a.resellers);

  const colorMap = {
    '667026e50d24c394b2435c82': '#2D373D', // campanha
    '66702b35e8e4de76d470b7fa': '#3d4c55', // comunicados
    '667026e50d24c394b2435c83': '#596e78', // cobrança
    '667026e50d24c394b2435c84': '#9ca6af', // comunicações do er
    '64beafcda8d3493c2718b90d': '#dee2e3', // outros
  };

  const colorsInOrder = dataInAscedingOrder?.map(column => colorMap[column.code]);

  const options: ReactEChartsProps['option'] = {
    dataset: [
      {
        dimensions: ['name', 'resellers', 'percentage', 'code'],
        source:
          dataInAscedingOrder?.map(column => [column.name, column.resellers, column.percentage, column.code]) ?? [],
      },
    ],
    xAxis: {
      show: !isMobile,
      type: 'category',
      axisLabel: {
        overflow: 'break',
        interval: 0,
        width: isTablet ? 146 : 200,
        margin: 16,
        rich: {
          span: {
            fontWeight: 'bold',
            padding: [4, 0],
          },
        },
      },
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: value => decimalNoFraction.format(value),
      },
    },
    series: {
      type: 'bar',
      colorBy: 'data',
      itemStyle: {
        color: params => colorMap[params.data[3]] || '#f0f0f0',
      },
      label: {
        show: !isMobile,
        position: 'inside',
        formatter: value => percentNoFraction.format(value.data[2] / 100),
        rich: {
          div: {
            align: 'center',
            lineHeight: 16,
          },
        },
      },
    },
    grid: {
      top: '10%',
      right: '2%',
      bottom: '2%',
      left: '2%',
      containLabel: true,
    },
    tooltip: {
      trigger: 'item',
      borderWidth: 0,
      confine: true,
      formatter: value => relationTooltip(chartTitle, value.name, value.marker, value.data[1], value.data[2]),
    },
  };

  return (
    <Card css={{ gridArea: gridArea }}>
      <CardTitle title={chartTitle} tooltip={tooltip} />
      <Flex justify="space-evenly" direction="column">
        <ReactECharts
          option={{ ...options }}
          style={{
            width: '100%',
            height: '360px',
          }}
          loading={isLoading}
        />
        {isMobile && dataInAscedingOrder && <ChartLegend columns={dataInAscedingOrder} colors={colorsInOrder} />}
      </Flex>
    </Card>
  );
}
