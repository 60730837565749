import { Flex } from '@grupoboticario/flora-react';

import { DailyChart, ToggleButton } from '@/shared/components';
import { useGetDashboardData } from '@/shared/hooks';
import { currencyNoFraction } from '@/shared/format';
import { useState } from 'react';

export function DailyRevenueDashboard() {
  const { data, isLoading } = useGetDashboardData({ queryKey: 'dailyRelation', path: '/revenue/daily' });
  const [displayedChart, setDisplayedChart] = useState<'daily' | 'accumulated'>('daily');
  return (
    <Flex direction="column">
      <Flex justify="flex-end">
        <ToggleButton
          onLeftButtonClick={() => setDisplayedChart('daily')}
          leftText="Diarizado"
          onRightButtonClick={() => setDisplayedChart('accumulated')}
          rightText="Acumulado"
          isLoading={isLoading}
        />
      </Flex>
      <DailyChart
        data={data?.revenueDailyGmv}
        isLoading={isLoading}
        mainColor="#364372"
        chartTitle="Receita GMV"
        tooltipText="Valor total dos pedidos realizados pelos revendedores. O valor é composto pela Receita GMV, por dia do ciclo."
        format={currencyNoFraction.format}
        displayedChart={displayedChart}
      />
      <DailyChart
        data={data?.revenueDailyProductivity}
        isLoading={isLoading}
        mainColor="#364372"
        chartTitle="Reais por ativo"
        tooltipText="Quantidade de revendedores que possuem pedido no ciclo corrente, desconsiderando Inícios e Reinícios, por dia do ciclo."
        format={currencyNoFraction.format}
        displayedChart={displayedChart}
      />
    </Flex>
  );
}
