import { DEFAULT_LOCALE } from './locale';

export const decimal = new Intl.NumberFormat(DEFAULT_LOCALE, { style: 'decimal' });

export const decimalNoFraction = new Intl.NumberFormat(DEFAULT_LOCALE, {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const decimalOnePlace = new Intl.NumberFormat(DEFAULT_LOCALE, {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
});
