import { Card, CardTitle, DashboardGrid, ToggleButton, TableSelector } from '@/shared/components';
import { Flex } from '@grupoboticario/flora-react';
import { Dispatch, SetStateAction, useState } from 'react';
import { gridSettings } from './grid-settings';
interface TableWrapperProps {
  tables;
  gridArea: string;
  title: string;
  tooltipText: string;
  hasToggle?: boolean;
  hasSelector?: boolean;
  isLoading?: boolean;
  setTableSelection?: Dispatch<SetStateAction<string>>;
  selectorKind?: string;
}

export function TableWrapper({
  tables,
  gridArea,
  title,
  tooltipText,
  hasToggle = false,
  hasSelector = false,
  isLoading,
  selectorKind,
}: TableWrapperProps) {
  const [selectedToggle, setSelectedToggle] = useState('channel');
  const [tableSelection, setTableSelection] = useState('Todos');
  const tablesData = tables.map(table => table.props.data);
  const options = tablesData[0]?.map(level => ({ label: level.name, value: level.code })).flat();

  function getDataFromSelectedReason(tableData) {
    return tableData?.props?.data?.find(table => table?.code === tableSelection)?.values;
  }

  const dataWithSelectedToggleOption = tables
    .map(table =>
      (table.props.data || []).map(obj =>
        Object.fromEntries(
          Object.entries(obj).filter(
            ([chave]) => chave === selectedToggle || (chave !== 'channel' && chave !== 'brand'),
          ),
        ),
      ),
    )
    .flat();

  return (
    <Card css={{ gridArea, width: '100%' }}>
      <Flex
        css={{
          flexDirection: 'column',
          '@tablet': { flexDirection: 'row' },
        }}
        justify="space-between"
        align="flex-start"
      >
        <CardTitle title={title} tooltip={tooltipText} />
        {hasToggle && (
          <ToggleButton
            isLoading={isLoading}
            leftText="Meio de captação"
            onLeftButtonClick={() => setSelectedToggle('channel')}
            rightText="Marcas"
            onRightButtonClick={() => setSelectedToggle('brand')}
          />
        )}
        {hasSelector && (
          <TableSelector
            selectorKind={selectorKind}
            setTableSelector={setTableSelection}
            defaultValue="Todos"
            options={options}
            isLoading={isLoading}
          />
        )}
      </Flex>
      {tables.length > 1 ? (
        <DashboardGrid css={gridSettings}>
          {tables.map(table => ({
            ...table,
            props: {
              ...table.props,
              data: getDataFromSelectedReason(table),
            },
          }))}
        </DashboardGrid>
      ) : (
        tables.map(table => {
          return {
            ...table,
            props: {
              ...table.props,
              data: dataWithSelectedToggleOption,
            },
          };
        })
      )}
    </Card>
  );
}
