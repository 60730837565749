import { Flex, Text } from '@grupoboticario/flora-react';
import { Select } from 'antd';

export const TableSelector = ({
  options,
  setTableSelector,
  isLoading,
  defaultValue,
  selectorKind,
}: {
  options;
  setTableSelector;
  defaultValue;
  isLoading: boolean;
  selectorKind: string;
}) => {
  const handleChange = (value: string) => {
    setTableSelector(value);
  };
  return (
    <Flex gap="$2" direction="column">
      <Text size="exceptionsAuxiliarMedium">{`Selecione um motivo ${selectorKind ? `de ${selectorKind}` : ''} `}</Text>
      <Select
        loading={isLoading}
        disabled={isLoading}
        defaultValue={defaultValue}
        placement="bottomLeft"
        optionLabelProp="label"
        onChange={handleChange}
        style={{ width: '260px' }}
        options={options}
      />
    </Flex>
  );
};
