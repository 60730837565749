import { percent } from './number';

export function setDisplayedValue(value, formatter) {
  return value !== undefined ? formatter.format(formatter === percent ? value / 100 : value) : '-';
}

export function shouldRenderCard(status) {
  return status === 'enabled' || status === 'partial' || status === 'maintenance';
}

export function shortNumberFormat(value: number, formatter: (number) => string) {
  if (value < 1000) {
    return formatter(value);
  }
  if (value < 1000000) {
    return `${formatter(value / 1000)} mil`;
  }
  return `${formatter(value / 1000000)} mi`;
}
