import { FilterDrawer, SubreasonProvider, TriggersDashboard, AttendanceDashboard } from '@/features';
import { PageTitle } from '@/shared/components';
import { useGetDashboardData } from '@/shared/hooks';
import { Flex } from '@grupoboticario/flora-react';

export function Relation() {
  const { data, isLoading } = useGetDashboardData({ queryKey: 'relation', path: '/relation/dashboard' });

  return (
    <SubreasonProvider>
      <Flex
        align="center"
        justify="space-between"
        css={{ borderBottom: '1px solid $nonInteractiveAltAuxiliar', paddingBottom: '$4' }}
      >
        <PageTitle>Indicadores de relacionamento com RE</PageTitle>
        <FilterDrawer />
      </Flex>
      <TriggersDashboard data={data} isLoading={isLoading} />
      <AttendanceDashboard data={data} isLoading={isLoading} />
    </SubreasonProvider>
  );
}
