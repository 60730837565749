import { Card, CardTitle, ChartLegend, ReactECharts, ReactEChartsProps } from '@/shared/components';
import { decimalNoFraction, percentNoFraction, dailyColumnTooltip } from '@/shared/format';

export interface ChartData {
  cycleDay: number;
  value?: number;
  percentage?: number;
  accumulatedValue?: number;
  accumulatedPercentage?: number;
  date?: string;
}

interface ColumnLineChartProps {
  data: ChartData[];
  isLoading: boolean;
  columnColor: string;
  lineColor: string;
  chartTitle: string;
  tooltipText: string;
  type?: 'acionamento' | 'atendimento';
}

export function ColumnLineChart({
  data,
  isLoading,
  columnColor,
  lineColor,
  chartTitle,
  tooltipText,
  type,
}: ColumnLineChartProps) {
  const isMobile = window.innerWidth < 768;
  const isTablet = window.innerWidth < 992;
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  const isAfterToday = (date: string) => new Date(date.split('/').reverse().join('-')) >= yesterday;

  const series: ReactEChartsProps['option']['series'] = [
    {
      type: 'bar',
      data: data?.map(item => ({ value: item.value, accumulatedValue: item.accumulatedValue, date: item.date })),
      itemStyle: {
        color: columnColor,
      },
    },
    {
      type: 'line',
      data: data?.map(item => ({
        percentage: item.percentage,
        value: isAfterToday(item.date) ? null : item.accumulatedPercentage,
      })),
      yAxisIndex: 1,
      itemStyle: {
        color: lineColor,
      },
      symbol: 'circle',
      symbolSize: 12,
      label: {
        show: true,
        position: 'top',
        formatter: ({ value }) => percentNoFraction.format(Number(value) / 100),
        fontSize: 14,
        textBorderColor: 'inherit',
        color: 'white',
        textBorderWidth: 3,
      },
    },
  ];

  const option: ReactEChartsProps['option'] = {
    xAxis: {
      type: 'category',
      data: data?.map(item => `Dia ${item.cycleDay}`),
      axisLabel: {
        interval: 0,
      },
    },
    yAxis: [
      {
        type: 'value',
        axisLabel: {
          formatter: value => (value < 1000000 ? decimalNoFraction.format(value) : `${value / 1000000} mi`),
        },
      },
      {
        type: 'value',
        max: function (value) {
          if (value.max < 100) return 100;
          const valueUntilTenMultiple = 10 - (value.max % 10);
          return value.max + valueUntilTenMultiple;
        },
        axisLabel: {
          formatter: value => percentNoFraction.format(value / 100),
        },
      },
    ],
    series,
    dataZoom: [
      {
        type: 'slider',
        show: isTablet,
        xAxisIndex: 0,
        start: 0,
        end: isMobile ? 20 : isTablet ? 38 : 100,
        maxSpan: isMobile ? 20 : isTablet ? 38 : 100,
        zoomLock: true,
        brushSelect: false,
        handleSize: 0,
      },
    ],
    tooltip: {
      trigger: 'axis',
      borderWidth: 0,
      confine: true,
      extraCssText: 'font:inherit;',
      formatter: value => dailyColumnTooltip(value, type),
    },
    grid: {
      top: '8%',
      right: isMobile ? '10%' : isTablet ? '8%' : 0,
      bottom: isTablet ? '14%' : '2%',
      left: '2%',
      containLabel: true,
    },
  };

  return (
    <Card css={{ height: '100%' }}>
      <CardTitle title={chartTitle} tooltip={tooltipText} />
      <ReactECharts
        option={{ ...option }}
        style={{
          width: '100%',
          height: '380px',
          marginBottom: '16px',
        }}
        loading={isLoading}
      />
      {!isLoading && (
        <ChartLegend
          columns={[
            { name: type === 'acionamento' ? 'REs com acionamento' : 'REs com atendimento' },
            { name: type === 'acionamento' ? 'Giro de base acumulado' : 'Contato efetivo acumulado' },
          ]}
          colors={[columnColor, lineColor]}
        />
      )}
    </Card>
  );
}
