import { ActivityDashboard } from '@/features/ActivityDashboard';
import { PageTitle, FilterDrawer } from '@/shared/components';
import { Flex } from '@grupoboticario/flora-react';

export function Activity() {
  return (
    <>
      <Flex align="center" justify="space-between">
        <PageTitle>Atividade</PageTitle>
        <FilterDrawer />
      </Flex>
      <ActivityDashboard />
    </>
  );
}
