import { ReactEChartsProps } from '@/shared/components';

export function setOptions({ colors, formatter }): ReactEChartsProps['option'] {
  return {
    color: colors,
    xAxis: [
      {
        show: false,
        type: 'category',
        triggerEvent: true,
      },
    ],
    yAxis: [
      {
        type: 'value',
        axisLabel: {
          formatter: value => formatter(value),
        },
      },
    ],
    grid: {
      top: '4%',
      right: '0',
      bottom: 0,
      left: '2%',
      containLabel: true,
    },
    legend: { show: false },
  };
}
