import { Hidden, Flex, Image, Text } from '@grupoboticario/flora-react';
import { StyledPercentage } from '@/shared/components';

export const multiClubClassification = {
  diamante: 'Diamante',
  esmeralda: 'Esmeralda',
  rubi: 'Rubi',
  platina: 'Platina',
  ouro: 'Ouro',
  prata: 'Prata',
  bronze: 'Bronze',
  novoRevendedor: 'Revendedor',
  indefinido: 'Em atualização',
};

function getImageMeuClubeGb(meuClubeGbLevel) {
  try {
    return require(`../../../../assets/images/meu-clube-gb-${meuClubeGbLevel.toLowerCase()}.png`);
  } catch (error) {
    return require(`../../../../assets/images/meu-clube-gb-fallback.png`);
  }
}

export function GbClubLevelName({ level }) {
  const isTotal = level === 'Total';
  return (
    <Flex css={{ width: '120px' }} align="center" wrap="nowrap">
      {level !== 'indefinido' && level !== 'novoRevendedor' && !isTotal && (
        <Hidden only={['mobile']}>
          <Image
            css={{
              marginInlineEnd: '$3',
              width: '40px',
              height: '40px',
              borderRadius: '100%',
              backgroundColor: 'rgba(188, 188, 188, 0.2)',
            }}
            src={getImageMeuClubeGb(level)}
            alt={`Meu Clube GB ${level}`}
            loading="lazy"
          />
        </Hidden>
      )}
      <Flex direction="column">
        <Text css={{ textAlign: 'left' }} size="bodyMediumStandard">
          {level === 'indefinido' ? 'Sem classificação' : multiClubClassification[level] || level}
        </Text>
        {level === 'indefinido' && <StyledPercentage align="left">{multiClubClassification[level]}</StyledPercentage>}
      </Flex>
    </Flex>
  );
}
