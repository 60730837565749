import { Flex } from '@grupoboticario/flora-react';
import { DailyChart, ToggleButton } from '@/shared/components';
import { useGetDashboardData } from '@/shared/hooks';
import { decimalNoFraction, percentOneFraction } from '@/shared/format';
import { useState } from 'react';

export function DailyActivityDashboard() {
  const { data, isLoading } = useGetDashboardData({ queryKey: 'dailyActivity', path: '/activity/daily' });
  const [displayedChart, setDisplayedChart] = useState<'daily' | 'accumulated'>('daily');

  return (
    <Flex direction="column">
      <Flex justify="flex-end">
        <ToggleButton
          onLeftButtonClick={() => setDisplayedChart('daily')}
          leftText="Diarizado"
          onRightButtonClick={() => setDisplayedChart('accumulated')}
          rightText="Acumulado"
          isLoading={isLoading}
        />
      </Flex>

      <DailyChart
        data={data?.totalBaseActives}
        isLoading={isLoading}
        mainColor="#52886D"
        chartTitle="Ativos da base total"
        tooltipText="Quantidade de revendedores que possuem pedido no ciclo corrente, considerando inícios e reinícios, por dia do ciclo."
        format={decimalNoFraction.format}
        displayedChart={displayedChart}
      />
      <DailyChart
        data={data?.activeBaseActives}
        isLoading={isLoading}
        mainColor="#52886D"
        chartTitle="Ativos da base ativa"
        tooltipText="Quantidade de revendedores que possuem pedido no ciclo corrente, desconsiderando inícios e reinícios, por dia do ciclo."
        format={decimalNoFraction.format}
        displayedChart={displayedChart}
      />
      <DailyChart
        data={data?.multibrandActives}
        isLoading={isLoading}
        mainColor="#52886D"
        chartTitle="Ativas multimarca"
        tooltipText="Quantidade de revendedores que tiveram pedido com itens de mais de uma marca, por dia do ciclo."
        format={decimalNoFraction.format}
        displayedChart={displayedChart}
      />
      <DailyChart
        data={data?.erChannelsPenetration}
        isLoading={isLoading}
        mainColor="#52886D"
        chartTitle="Penetração no ER"
        tooltipText="Percentual de revendedores ativos que tiveram pelo menos um pedido realizado no Espaço do Revendedor, por dia do ciclo."
        format={value => percentOneFraction.format(value / 100)}
        displayedChart={displayedChart}
      />
    </Flex>
  );
}
