export const gridSettings = (showActiveBase = false) => {
  if (showActiveBase) {
    return {
      '@mobile': {
        gridTemplateAreas: `
    "active-base"
    "total-resellers"
    "distinct-resellers"
    "base-rotation"
    "triggers-chart"
    "tables"
    `,
        gridTemplateColumns: 'repeat(1, 1fr)',
      },
      '@tablet': {
        gridTemplateAreas: `
    "active-base active-base total-resellers total-resellers"
    "distinct-resellers distinct-resellers base-rotation base-rotation"
    "triggers-chart triggers-chart triggers-chart triggers-chart"
    "tables tables tables tables"
    `,
        gridTemplateColumns: 'repeat(4, 1fr)',
      },
      '@desktop': {
        gridTemplateAreas: `
    "active-base active-base active-base total-resellers total-resellers total-resellers distinct-resellers distinct-resellers distinct-resellers base-rotation base-rotation base-rotation"
    "triggers-chart triggers-chart triggers-chart triggers-chart triggers-chart triggers-chart triggers-chart triggers-chart triggers-chart triggers-chart triggers-chart triggers-chart"
    "tables tables tables tables tables tables tables tables tables tables tables tables"
    `,
        gridTemplateColumns: 'repeat(12, 1fr)',
      },
    };
  }
  return {
    '@mobile': {
      gridTemplateAreas: `
  "total-resellers"
  "distinct-resellers"
  "base-rotation"
  "triggers-chart"
  "tables"
  `,
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
    '@tablet': {
      gridTemplateAreas: `
  "total-resellers total-resellers total-resellers total-resellers"
  "distinct-resellers distinct-resellers base-rotation base-rotation"
  "triggers-chart triggers-chart triggers-chart triggers-chart"
  "tables tables tables tables"
  `,
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
    '@desktop': {
      gridTemplateAreas: `
  "total-resellers total-resellers total-resellers total-resellers distinct-resellers distinct-resellers distinct-resellers distinct-resellers base-rotation base-rotation base-rotation base-rotation"
  "triggers-chart triggers-chart triggers-chart triggers-chart triggers-chart triggers-chart triggers-chart triggers-chart triggers-chart triggers-chart triggers-chart triggers-chart"
  "tables tables tables tables tables tables tables tables tables tables tables tables"
  `,
      gridTemplateColumns: 'repeat(12, 1fr)',
    },
  };
};
