import { Card, CardTitle, ChartLegend, ReactECharts, ReactEChartsProps } from '@/shared/components';
import { dailyChartTooltip, decimalNoFraction, shortNumberFormat } from '@/shared/format';
import { Box, Button, Flex, Text } from '@grupoboticario/flora-react';
import { GearIcon } from '@grupoboticario/flora-react-icons';
import { lighten } from 'polished';
import { useEffect, useState } from 'react';

enum ChartTypes {
  TREND = 'trend',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  OUTLINE = 'outline',
  HIGHLIGHT = 'highlight',
}

export interface ChartData {
  cycleDay: number;
  date: string;
  daily: {
    name: string;
    value: number;
    accumulated?: boolean;
    type: ChartTypes;
  }[];
  accumulated?: {
    name: string;
    value: number;
    accumulated?: boolean;
    type: ChartTypes;
  }[];
}

interface DailyChartProps {
  data: ChartData[];
  isLoading: boolean;
  chartTitle: string;
  tooltipText: string;
  mainColor: string;
  displayedChart?: 'daily' | 'accumulated';
  hasSettings?: boolean;
  format?: (value: number) => string;
}

export function DailyChart({
  data,
  isLoading,
  chartTitle,
  tooltipText,
  mainColor,
  displayedChart = 'daily',
  hasSettings = false,
  format = decimalNoFraction.format,
}: DailyChartProps) {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [isShowingTrend, setIsShowingTrend] = useState(true);
  const [isShowingDataLabel, setIsShowingDataLabel] = useState(true);
  const [forceUpdate, setForceUpdate] = useState(1);

  const isMobile = window.innerWidth < 768;
  const isTablet = window.innerWidth < 992;

  const invoiceColor = lighten(0.2, mainColor);
  const trendColor = lighten(0.4, mainColor);

  function getItemSettings(name: string, chartType: string, accumulated = false) {
    if (accumulated) {
      return { accumulated };
    }
    if (chartType === ChartTypes.PRIMARY) {
      return { name, type: displayedChart === 'accumulated' ? 'line' : 'bar', color: mainColor };
    }
    if (chartType === ChartTypes.SECONDARY) {
      return { name, type: 'bar', color: invoiceColor };
    }
    if (chartType === ChartTypes.OUTLINE) {
      return { name, type: 'line', color: '#C85035' };
    }
    if (chartType === ChartTypes.HIGHLIGHT) {
      return { name, type: 'line', color: '#971B2F' };
    }
    if (chartType === ChartTypes.TREND) {
      return { name, type: 'bar', color: trendColor };
    }
  }

  const displayedFields = data
    ?.map((item, index) => ({
      name: item[displayedChart][index]?.name,
      value: item[displayedChart][index]?.value,
      chartType: item[displayedChart][index]?.type,
      accumulated: item[displayedChart][index]?.accumulated,
    }))
    .filter(item => item.name !== undefined && item?.value > 0)
    .map(item => getItemSettings(item.name, item.chartType, item.accumulated));

  const series: ReactEChartsProps['option']['series'] = displayedFields?.map(field => {
    if (field?.accumulated) return null;
    const commonProps = {
      name: field?.name,
      color: field?.color,
      data: data?.map(item => {
        const accumulatedItem = item[displayedChart].find(acc => acc.accumulated);
        return {
          value: item[displayedChart].find(
            value => value.name === field?.name && field?.accumulated === value.accumulated,
          )?.value,
          ...(accumulatedItem && {
            accumulated: {
              value: accumulatedItem.value,
              name: accumulatedItem.name,
            },
          }),
        };
      }),
      symbolSize: 8,
      label: {
        formatter: value => (value?.data.value > 0 ? shortNumberFormat(value?.data.value, format) : ''),
        show: isShowingDataLabel,
        fontSize: 14,
        textBorderColor: 'inherit',
        color: 'white',
        textBorderWidth: 3,
      },
    };

    if (field?.name === 'Meta do ciclo') {
      return {
        ...commonProps,
        type: 'line',
        symbol: 'none',
        lineStyle: {
          type: 'dashed',
          width: 1,
        },
      };
    }

    if (field?.type === 'line') {
      return {
        ...commonProps,
        type: 'line',
        symbol: 'circle',
        lineStyle: {
          type: 'solid',
          width: 2,
        },
      };
    }

    if (field?.type === 'bar') {
      return {
        ...commonProps,
        type: 'bar',
        stack: 'total',
        barWidth: '60%',
      };
    }
  });

  const option: ReactEChartsProps['option'] = {
    grid: {
      top: '8%',
      right: '2%',
      bottom: '12%',
      left: '2%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      data: data?.map(item => `Dia ${item.cycleDay}`),
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: value => shortNumberFormat(value, format),
      },
    },
    series,
    tooltip: {
      trigger: 'axis',
      borderWidth: 0,
      confine: true,
      extraCssText: 'font:inherit;',
      formatter: value => dailyChartTooltip(value, displayedChart, data, format),
    },
    dataZoom: [
      {
        type: 'slider',
        show: isTablet,
        xAxisIndex: 0,
        start: 0,
        end: isMobile ? 20 : isTablet ? 38 : 100,
        maxSpan: isMobile ? 20 : isTablet ? 38 : 100,
        zoomLock: true,
        brushSelect: false,
        handleSize: 0,
      },
    ],
  };

  useEffect(() => {
    setForceUpdate(forceUpdate + 1);
  }, [displayedChart, displayedFields?.length]);

  return (
    <Card css={{ height: '100%', marginTop: '$4' }}>
      <Flex
        css={{
          flexDirection: 'column',
          '@tablet': { flexDirection: 'row', alignItems: 'center' },
        }}
        justify="space-between"
        gap="$1"
      >
        <CardTitle title={chartTitle} tooltip={tooltipText} />
        {hasSettings && (
          <Flex>
            <Button onClick={() => setIsOptionsOpen(!isOptionsOpen)} size="small" hierarchy="secondary">
              <GearIcon />
            </Button>
            {isOptionsOpen && (
              <Box
                onClick={() => setIsOptionsOpen(false)}
                css={{ width: '100%', height: '100vh', position: 'fixed', top: 0, left: 0 }}
              />
            )}
            {isOptionsOpen && (
              <Flex
                css={{
                  position: 'absolute',
                  top: '72px',
                  right: '20px',
                  zIndex: 1,
                  background: 'white',
                  borderRadius: '8px',
                  outline: 'none',
                  boxShadow: '0px 0px 16px 0px #0000003D',
                }}
                direction="column"
              >
                <Text
                  css={{
                    padding: '$4',
                    width: '300px',
                    borderBottom: '1px solid $nonInteractiveOutline',
                    backgroundColor: 'white',
                    borderRadius: '8px 8px 0 0',
                    transition: 'background-color 0.2s',
                    '&:hover': { backgroundColor: '#00000012' },
                  }}
                  size="bodyLargeStandardRegular"
                  onClick={() => setIsShowingTrend(!isShowingTrend)}
                >
                  {`${isShowingTrend ? 'Ocultar' : 'Exibir'} barra de tendência`}
                </Text>
                <Text
                  css={{
                    padding: '$4',
                    width: '300px',
                    borderBottom: '1px solid $nonInteractiveOutline',
                    backgroundColor: 'white',
                    borderRadius: '0 0 8px 8px',
                    transition: 'background-color 0.2s',
                    '&:hover': { backgroundColor: '#00000012' },
                  }}
                  size="bodyLargeStandardRegular"
                  onClick={() => setIsShowingDataLabel(!isShowingDataLabel)}
                >
                  {`${isShowingDataLabel ? 'Ocultar' : 'Exibir'} rótulo de dados`}
                </Text>
              </Flex>
            )}
          </Flex>
        )}
      </Flex>
      <ReactECharts
        option={{ ...option }}
        key={forceUpdate}
        style={{
          width: '100%',
          height: '380px',
        }}
        loading={isLoading}
      />
      {data && (
        <ChartLegend
          columns={displayedFields.filter(item => !item?.accumulated)}
          colors={displayedFields.map(item => item?.color)}
        />
      )}
    </Card>
  );
}
