import { Flex, Text } from '@grupoboticario/flora-react';
import { Select } from 'antd';
import { useEventTracker } from '@/shared/hooks';
import { FilterCheckbox, FilterTag, useFilters, useFiltersDispatch } from '@/features';

const { Option } = Select;

export const FilterCycle = ({ isLoading }) => {
  const { selectedCycles, cycles } = useFilters();
  const dispatch = useFiltersDispatch();
  const { clickedOnFilter } = useEventTracker();

  function handleCycleSelection(value) {
    dispatch({
      type: 'SELECT_CYCLE',
      payload: value,
    });
  }

  const minItemsSelected = selectedCycles?.length === 1;
  const maxItemsSelected = selectedCycles?.length === 17;

  return (
    <Flex direction="column" wrap="wrap" title="cycle-filter" css={{ width: '100%' }}>
      <Text as="label" size="exceptionsRestrictedRegular" htmlFor="cycle">
        Ciclo
      </Text>
      <Select
        showSearch
        mode="multiple"
        disabled={isLoading}
        loading={isLoading}
        tagRender={props => (
          <FilterTag
            isMaxTag={props.isMaxTag}
            onClose={props.onClose}
            label={props.label}
            isLoading={isLoading}
            structureType={undefined}
          />
        )}
        maxTagPlaceholder={omittedValues => <Text>{`+ ${omittedValues.length}`}</Text>}
        style={{ width: '100%' }}
        placeholder="Pesquise ou selecione"
        placement="bottomLeft"
        id="cycle"
        onChange={value => handleCycleSelection(value)}
        filterOption={(input, option) => option?.label.toString().toLowerCase().includes(input.toLowerCase())}
        maxTagCount={1}
        value={selectedCycles}
        optionLabelProp="label"
        onFocus={() => clickedOnFilter({ filterName: 'ciclo' })}
      >
        {cycles?.map(item => {
          if (!item.enabled) return null;

          return (
            <Option
              data-testid="cycle-filter-option"
              disabled={(minItemsSelected && item.selected) || (maxItemsSelected && !item.selected)}
              title={`${item.code} - ${item.name}`}
              key={item.code}
              value={item.code}
              label={`${item.code} - ${item.name}`}
              style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              <FilterCheckbox selected={item.selected} id="cycle" />
              <Text>{item.name}</Text>
            </Option>
          );
        })}
      </Select>
    </Flex>
  );
};
