import { DailyActivityDashboard } from '@/features';
import { ActivityDashboard } from '@/features/ActivityDashboard';
import { PageTitle, FilterDrawer, TabNavigation, Notification } from '@/shared/components';
import { Flex } from '@grupoboticario/flora-react';
import { useState } from 'react';

export function Activity() {
  const [isDailyDashboard, setIsDailyDashboard] = useState(true);

  return (
    <>
      <Flex align="center" justify="space-between">
        <PageTitle>Atividade</PageTitle>
        <FilterDrawer setIsDailyDashboard={setIsDailyDashboard} isDailyDashboard={isDailyDashboard} />
      </Flex>
      <Notification />
      <TabNavigation
        setIsDailyDashboard={setIsDailyDashboard}
        consolidated={<ActivityDashboard />}
        daily={<DailyActivityDashboard />}
      />
    </>
  );
}
