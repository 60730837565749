import { useState } from 'react';
import { DailyRevenueDashboard, RevenueDashboard } from '@/features';
import { PageTitle, FilterDrawer, Notification, TabNavigation } from '@/shared/components';
import { Flex } from '@grupoboticario/flora-react';

export function Revenue() {
  const [isDailyDashboard, setIsDailyDashboard] = useState(true);

  return (
    <>
      <Flex align="center" justify="space-between">
        <PageTitle>Receita</PageTitle>
        <FilterDrawer setIsDailyDashboard={setIsDailyDashboard} isDailyDashboard={isDailyDashboard} />
      </Flex>
      <Notification />
      <TabNavigation
        setIsDailyDashboard={setIsDailyDashboard}
        consolidated={<RevenueDashboard />}
        daily={<DailyRevenueDashboard />}
      />
    </>
  );
}
