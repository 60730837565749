import { VdiLogo } from '@/assets/icons/vdi-logo';
import { IndicatorsSearch } from '@/features';
import { Flex, Text, styled } from '@grupoboticario/flora-react';

const PageContainer = styled('div', {
  maxWidth: '1360px',
  marginInline: 'auto',
  padding: '$6',
});

export function CalculationLog() {
  return (
    <PageContainer>
      <Flex css={{ marginTop: '32px' }} align="center" justify="center" direction="column">
        <VdiLogo />
        <Text css={{ marginTop: '16px' }} size="subTitleDeskBold" color="$nonInteractiveEmphasis">
          Memória de Cálculo dos Indicadores
        </Text>
        <Text
          css={{ maxWidth: '620px', marginTop: '8px' }}
          size="bodySmallStandardRegular"
          align="center"
          color="$nonInteractivePredominant"
        >
          Nesta página, você encontrará uma lista completa de indicadores disponíveis na VDI, incluindo seus nomes,
          descrições detalhadas e memórias de cálculo. Utilize o campo de pesquisa para encontrar o indicador desejado.
        </Text>
      </Flex>
      <IndicatorsSearch />
    </PageContainer>
  );
}
